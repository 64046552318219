import React, { useEffect, useRef } from 'react'
import kad from './kad.jpg'
import gogh from './gogh.jpg'
import './home.css'
import background from './backgroundweb.jpeg'
import IOShowcase from './IOShowcase'
import { Link } from 'react-router-dom'
import './transitions.css'

const ClientApp = React.forwardRef(function client ({ user, loginClick }, ref) {
  const refs = [useRef(), useRef(), useRef(), useRef()]
  const timeouts = []
  useEffect(() => {
    if (refs[0].current && refs[1].current && refs[2].current && refs[3].current) {
      if (!refs[0].current.classList.contains('appear')) {
        const options = { root: null, rootMargin: '0px 0px -200px 0px', threshold: 0 }
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('appear')
              observer.unobserve(entry.target)
            }
          })
        }, options)
        for (let i = 0; i < refs.length; i++) {
          timeouts[i] = setTimeout(() => {
            observer.observe(refs[i].current)
          }, 500)
        }

        return () => {
          for (let i = 0; i < timeouts.length; i++) {
            clearTimeout(timeouts[i])
          }
          observer.disconnect()
        }
      }
    }
  }, [])
  return (
    <div className='mainpage' style={{ width: '100%' }}>
      <div className="backgroundContainer" ref={ref}>
        <img src={background} className="background"/>
        <blockquote className="kQuote">
        <h1 className="kQuoteH">
          &#39;Each color lives by its mysterious life.&#39;
        </h1>
        <figcaption className="kQuoteF">
          Wassily Kandinsky.
        </figcaption>
        </blockquote>
        <div className="invitation">
          {user
            ? <Link to="/transformation">
            <button className="btn btn-info" style={{ textAlign: 'left', padding: '2px 10px' }}>
                  <span id="invitationText">
                    Try it yourself!
                  </span>
            </button>
              </Link>
            : <button className="btn btn-info" onClick={loginClick} style={{ textAlign: 'left', padding: '2px 10px' }}>
                  <span id="invitationText">
                    Try it yourself!
                  </span>
            </button>}
        </div>

    </div>
    <div style={{ width: '100%' }} className="bottomcontainer">
      <div className="container" style={{ marginTop: '40px' }}>
        <h2>
          Turn any photo into a painting
        </h2>
        <p>Using a combination of original photography and artistic styles by legacy artists, we create beautiful works of art using artificial intelligence deep learning techniques. Our goal is to give you access to the color palettes and brush strokes of the old masters as well as provide a way for existing artists to experiment with their own style.</p>
        <IOShowcase/>
      <div className="row justify-content-left" style={{ marginBottom: '20px' }}>
      <div className="col-xl invitationbelow">
        {user
          ? <Link to="/transformation">
          <button className="btn btn-info" style={{ textAlign: 'left', padding: '2px 10px' }}>
                <span id="invitationText">
                  Try it yourself!
                </span>
          </button>
            </Link>
          : <button className="btn btn-info" onClick={loginClick} style={{ textAlign: 'left', padding: '2px 10px' }}>
                <span id="invitationText">
                  Try it yourself!
                </span>
          </button>}

      </div>
      </div>
      </div>
      <div style={{ width: '72%', margin: 'auto' }}>
      </div>
      <div style={{ width: '100%', padding: '40px 0px 50px 0px' }} className="bg-light">
        <div className="container" >
          <h2>
          Our favorite artists
          </h2>
          <div className="row slider justify-content-between">
            <div className="from-left col-lg-6" ref={refs[0]} style={{ position: 'relative' }}>
              <img src={kad} />
            </div>
            <div className="from-right col-lg-5" style={{ position: 'relative' }} ref={refs[1]}>
              <p>
              Kandinsky was fascinated and stimulated by color. According to Kandinsky each color lives by its mysterious life. A few years later he first likened painting to composing music in the manner for which he would become noted, writing, Color is the keyboard, the eyes are the hammers, the soul is the piano with many strings. The artist is the hand which plays, touching one key or another, to cause vibrations in the soul. Here is one of our works based on his color composition and brush strokes.
              </p>
            </div>
          </div>
          <div className="row slider justify-content-between" style={{ marginTop: '100px' }}>
            <div className="from-right col-lg-6 order-lg-2" style={{ position: 'relative' }} ref={refs[3]}>
              <img src={gogh} />
            </div>
            <div className="from-left col-lg-5 order-lg-1" style={{ position: 'relative' }} ref={refs[2]}>
              <p>
              Vincent Willem van Gogh was a Dutch post-impressionist painter who posthumously became one of the most famous and influential figures in the history of Western art. Born into an upper-middle-class family, Van Gogh drew as a child and was serious, quiet, and thoughtful. His paintings grew brighter in colour as he developed a style that became fully realised during his stay in Arles in the south of France in 1888. On our website you can experiment with your photos and look at them through the eye of Vincent van Gogh.
              </p>
            </div>
          </div>
        </div>
        </div>
      <div style={{ width: '100%', paddingTop: '40px' }}>
        <div className="container social" >
        <div className= "row justify-content-around">
            <div className="col-lg-6" style={{ marginBottom: '40px' }}>

                    <h3>Check us out on Instagram, and Facebook</h3>
                    <p>
                      Where we post our favorite artworks daily.
                    </p>
                    <div style={{ marginTop: '55px', marginBottom: '0', padding: 0 }} className="row justify-content-center">
                    <p className="col-lg-4">
                      Instagram:
                      <div style={{ fontSize: '.9em' }}>
                      <a href="https://www.instagram.com/dionyziuz/">
                      @Dionyziuz
                      </a>
                      </div>
                    </p>
                    <p className="col-lg-4">
                      Facebook:
                      <div style={{ fontSize: '.9em' }}>
                      <a href="https://www.facebook.com/dionyziuz/">
                      @Dionyziuz
                      </a>
                      </div>
                    </p>
                    </div>
            </div>
        </div>
        </div>
      </div>

      <div style={{ width: '100%', paddingBottom: '40px' }} className="bg-light">
        <div className="container"style={{ paddingTop: '40px' }}>
          <h2>
            About us
          </h2>
              <div className="row justify-content-around" style={{ marginTop: '40px' }}>
                  <div className="col-md-4 col-xs-4">
                      <h4 className="border-bottom">Lucas Jongsma</h4>
                      <p>
                          Fullstack developer at Dionyziuz, was convinced by his dad that this was a cool project.
                      </p>
                  </div>
                  <div className="col-md-4 col-xs-4">
                      <h4 className="border-bottom">Ursula Stauder</h4>
                      <p>
                          Responsible for the selection of legacy artists and existing models available on our site.
                      </p>
                  </div>
                  <div className="col-md-4 col-xs-4">
                      <h4 className="border-bottom">Edwin Jongsma</h4>
                      <p>
                          Develops the backend and workflow of multiple deep learning models that create the art works.
                      </p>
                  </div>
            </div>

        </div>
      </div>
        <div style={{ width: '100%' }} className="bg-dark">
                <Link to="/tos"><span className="tosLink">Terms of service</span></Link>
        </div>
    </div>
  </div>
  )
})

export default ClientApp
